.pages, #page ul {
  text-align: center;
  margin-top: 0.8rem;
}

@media (max-width: 1024px) {
  .pages, #page ul {
    margin-top: 30px;
  }
}

.pages a, .pages li, #page ul a, #page ul li {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid #a8acbc;
  transition: all .3s;
  width: 0.44rem;
  height: 0.44rem;
}

@media (max-width: 1024px) {
  .pages a, .pages li, #page ul a, #page ul li {
    width: 34px;
  }
}

@media (max-width: 767px) {
  .pages a, .pages li, #page ul a, #page ul li {
    width: 30px;
  }
}

@media (max-width: 1024px) {
  .pages a, .pages li, #page ul a, #page ul li {
    height: 34px;
  }
}

@media (max-width: 767px) {
  .pages a, .pages li, #page ul a, #page ul li {
    height: 30px;
  }
}

.pages a:not(:last-child), .pages li:not(:last-child), #page ul a:not(:last-child), #page ul li:not(:last-child) {
  margin-right: 0.1rem;
}

@media (max-width: 1024px) {
  .pages a:not(:last-child), .pages li:not(:last-child), #page ul a:not(:last-child), #page ul li:not(:last-child) {
    margin-right: 5px;
  }
}

.pages a.a1, .pages li.a1, #page ul a.a1, #page ul li.a1 {
  color: #a8acbc;
}

.pages a:hover, .pages li:hover, #page ul a:hover, #page ul li:hover {
  border: 1px solid #002fa7;
  color: #002fa7;
}

.pages a.active, .pages li.active, #page ul a.active, #page ul li.active {
  border: 1px solid #002fa7;
  color: #002fa7;
}

.pages a .iconfont, .pages li .iconfont, #page ul a .iconfont, #page ul li .iconfont {
  font-size: 0.2rem;
}

@media (max-width: 1024px) {
  .pages a .iconfont, .pages li .iconfont, #page ul a .iconfont, #page ul li .iconfont {
    font-size: 16px;
  }
}
