@import "../../assets/styles/utils";

.ny-banner{
  position: relative;
  overflow: hidden;
  color: #fff;
  @include res(height,9rem,(md:600px,sm:500px,xs:360px));
  &::after{
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    background-image: linear-gradient(to right,rgba(9,21,45,.6),rgba(9,21,45,.2));
  }
  &.t-center{
    .t{
      @include res(bottom,calc(50% - .4rem),(xs:20%));
      @include res(transform,translateY(50%),(xs:translateY(0)));
      @include res(padding-left,55%,(xs:5%));
    }
  }
  .bg{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 0;
  }
  .t{
    position: absolute;
    width: 100%;
    left: 0;
    z-index: 2;
    @include res(bottom,20%);
    h1{
      font-weight: lighter;
      text-transform: uppercase;
      @include res(font-size,.72rem,(md:50px,sm:44px,xs:38px));
      @include res(margin-bottom,.1rem,(md:10px));
      &::before{
        content: '';
        display: block;
        background-color: #fff;
        @include res(height,.03rem,(md:3px,sm:2px));
        @include res(width,1.35rem,(md:100px,sm:80px,xs:60px));
        @include res(margin-bottom,.1rem,(md:10px));
      }
    }
    .desc{
      @include res(font-size,.24rem,(md:20px,sm:18px,xs:16px));
    }
  }
  .crumbs_a{
    color: rgba(255, 255, 255, 0.8);
    z-index: 999;
    position: absolute;
    width: 100%;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: end;
    justify-content: flex-end;
    padding-bottom: 30px;
    flex-wrap: wrap;
    @include res(padding-bottom,.3rem,(sm:18px));
    @include res(font-size,.16rem,(sm:14px));
    a{
      transition: all .3s;
      display: inline-block;
      @include res(margin-right,.05rem,(md:5px));
      &:hover{
        color: rgba(255, 255, 255, 1);
      }
      &:not(:first-child){
        @include res(margin-left,.05rem,(md:5px));
      }
    }
  }
}

.page-en{
  .ny-banner{
    .t{
      h1{
         
        @include res(line-height,null,(md:1));
        @include res(font-size,.6rem,(md:40px,sm:36px,xs:32px));
        @include res(margin-bottom,.1rem,(md:20px));
        &::before{
          @include res(margin-bottom,.1rem,(md:20px));
        }
      }
      .desc{
        @include res(font-size,.22rem,(md:20px,sm:18px,xs:16px));
      }
    }
  }
}