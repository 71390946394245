@import "./utils";
@import "~jason-css/src/responsive/index";
@import "~swiper/css/swiper.min.css";
@import '~@fancyapps/fancybox/dist/jquery.fancybox.min.css';
@import './animate.css';
@import "../fonts/iconfont.css";

@font-face {
  font-family: 'aven';
  src: url('../fonts/avenirnextregular.woff') format('woff'),
    url('../fonts/avenirnextregular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
// @font-face {
//   font-family: 'sync_r';
//   src: url('../fonts/Syncopate-Regular.woff') format('woff'),
//     url('../fonts/Syncopate-Regular.ttf') format('truetype');
//   font-weight: normal;
//   font-style: normal;
// }
// @font-face {
//   font-family: 'sync';
//   src: url('../fonts/Syncopate-Bold.woff') format('woff'),
//     url('../fonts/Syncopate-Bold.ttf') format('truetype');
//   font-weight: normal;
//   font-style: normal;
// }
//中文字体
@font-face {
  font-family: 'WeiJunHe_W1';
  src: url('../fonts/WeiJunHe_W1.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'WeiJunHe_W2';
  src: url('../fonts/WeiJunHe_W2.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
// 英文字体
// 正文
@font-face {
  font-family: 'SourceSansPro';
  src: url('../fonts/SourceSansPro-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

// // 标题一版
// @font-face {
//   font-family: 'Cabin';
//   src: url('../fonts/Cabin-Medium.otf') format('opentype');
//   font-weight: normal;
//   font-style: normal;
// }
// // 标题二版
// @font-face {
//   font-family: 'OpenSans';
//   src: url('../fonts/OpenSans-Bold.ttf') format('truetype');
//   font-weight: normal;
//   font-style: normal;
// }
// // 标题三版
// @font-face {
//   font-family: 'ClearSans';
//   src: url('../fonts/ClearSans-Bold.ttf') format('truetype');
//   font-weight: normal;
//   font-style: normal;
// }

// // 首页标题四版
// @font-face {
//   font-family: 'EnigmaB_2';
//   src: url('../fonts/EnigmaB_2.ttf') format('truetype');
//   font-weight: normal;
//   font-style: normal;
// }
// // 首页标题五版
@font-face {
  font-family: 'Days';
  src: url('../fonts/Days.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}
// 首页标题五版 banner字体
// @font-face {
//   font-family: 'JosefinSans';
//   src: url('../fonts/JosefinSans-Regular.ttf') format('truetype');
//   font-weight: normal;
//   font-style: normal;
// }
// 首页标题六版
// @font-face {
//   font-family: 'Gaspar_Bold';
//   src: url('../fonts/Gaspar_Bold.otf') format('opentype');
//   font-weight: normal;
//   font-style: normal;
// }


*{
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  outline:none;
}
html{
  font-size: 62.5%;
}
body {
  font-family: "WeiJunHe_W1","PingFang SC","Microsoft YaHei";
  line-height: 1.6;
  position: relative;
  color: $color-text;
  background-color: #fff;
  @include res(font-size, 12px);
  &.page-en{
    font-family: "SourceSansPro","PingFang SC","Microsoft YaHei";
  }
}
.comp-root{
  overflow-x: hidden;
  @include res(font-size,.2rem,(md:16px));
}

//主体内容部分宽度
.container {
  @include res(padding-left, 12.5%, 5 / 12.5);
  @include res(padding-right, 12.5%, 5 / 12.5);
}

.bold{
  font-family: "WeiJunHe_W2","PingFang SC","Microsoft YaHei";
  text-transform: uppercase;
}
.font50{
  @include res(font-size,.5rem,(md:36px,sm:30px,xs:.6rem));
}
.font55{
  @include res(font-size,.55rem,(md:36px,sm:30px,xs:.6rem));
}
.page-en{
  .bold{
    font-family: "Days","PingFang SC","Microsoft YaHei";
    text-transform: uppercase;
  }
  .font50{
    line-height: 1.2;
    @include res(font-size,.44rem,(md:34px,sm:30px,xs:.56rem));
  }
  .font55{
    line-height: 1.2;
    @include res(font-size,.44rem,(md:34px,sm:30px,xs:.56rem));
  }
}

.pro-list-item{
  .font55{
    @include res(font-size,.46rem,(md:35px,sm:30px,xs:.6rem));
  }
}


.comp-crumbs{
  text-transform: uppercase;
  border-bottom: solid $color-line;
  @include res(padding-top,.15rem,(md:10px));
  @include res(padding-bottom,.15rem,(md:10px));
  @include res(font-size,.14rem,(md:14px));
  @include res(border-bottom-width,.01rem,(md:1px));
  // @include res(margin-bottom,.5rem,(md:25px,sm:30px,xs:10px));
  @include res(margin-bottom,.3rem,(md:25px,sm:30px,xs:10px));
  opacity: 0;
  display: none;
  &.place_hidden{
    display: block;
  }
  a{
    transition: all .3s;
    display: none;
    @include res(margin-right,.05rem,(md:5px));
    &:hover{
      color: $color-main;
    }
    &:not(:first-child){
      @include res(margin-left,.05rem,(md:5px));
    }
  }
}

.comp-sub-nav,.comp-sub-nav-three{
  position: relative;
  text-transform: uppercase;
  @include res(margin-bottom,.2rem,(md:10px,xs:20px));
  &.fixed{
    .fixed-cont{
      position: fixed;
      box-shadow: 0 0 .2rem rgba(0,0,0,.1);
    }
  }
  &.trans{
    .fixed-cont{
      transition: all .5s;
      @include res(top,.7rem,$header-height-ratio);
    }
  }
  .fixed-cont{
    width: 100%;
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    background-color: #fff;
    white-space: nowrap;
    @include res(overflow-x,null,(sm:auto));
    @include res(display,null,(sm:flex));
  }
  a{
    display: inline-block;
    position: relative;
    transition: all .3s;
    @include res(font-size,.3rem,(md:20px,xs:18px));
    @include res(padding-left,.5rem,(md:20px,xs:10px));
    @include res(padding-right,.5rem,(md:20px,xs:10px));
    @include res(padding-bottom,.3rem,(md:15px,xs:10px));
    @include res(padding-top,.3rem,(md:15px,xs:10px));
    &::after{
      content: '';
      display: block;
      background-color: $color-main-light;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%) scaleX(0);
      transition: all .3s;
      transform-origin: center;
      @include res(height,.01rem,(md:1px));
      @include res(width,1.4rem,(md:100px,sm:80px,xs:50px));
    }
    &:not(:last-child){
      @include res(margin-right,.8rem,(md:30px,sm:20px,xs:10px));
    }
    &:hover{
      color: $color-main-light;
    }
    &.active{
      color: $color-main-light;
      &::after{
        transform: translateX(-50%) scaleX(1);
      }
    }
  }
}
.comp-sub-nav-three{
  a{
    margin: 0;
    @include res(padding,.1rem .2rem,(sm:.1rem .3rem));
    @include res(font-size,.2rem,(md:.3rem,sm:.4rem,xs:.3rem));
    &::after{
      display: none;
    }
    &.active{
      color: #fff;
      background-color: #0055b7;
    }
  }
  &.fixed{
    .fixed-cont{
      @include res(top,62px,(xs:49px));
    }
  }
  &.trans{
    .fixed-cont{
      transition: all .5s;
      @include res(top,122px,(xs:99px));
    }
  }
}
.page-en{
  .comp-sub-nav{
    font-weight: bold;
  }
}

.comp-half-title{
  line-height: 1;
  color: $color-main-dark;
  @include res(text-align,left,(xs:center));
  @include res(padding-left,50%,(xs:0));
  @include res(margin-bottom,.6rem,(md:40px,xs:30px));
  h2{
    border-bottom: solid $color-main-light;
    text-transform: uppercase;
    font-family: 'WeiJunHe_W2';
    @include res(width,80%,(xs:100%));
    @include res(border-bottom-width,.01rem,(md:1px));
    @include res(font-size,.55rem,(md:36px,sm:30px,xs:.6rem));
    @include res(padding-bottom,.45rem,(md:20px));
    @include res(margin-bottom,.45rem,(md:20px));
  }
  p{
    line-height: 1.6;
  }
}
.page-en{
  .comp-half-title h2{
    @include res(font-size,.44rem,(md:34px,sm:30px,xs:.56rem));
  }
}

.comp-swiper-pagination{
  position: static;
  @include res(margin-top,.3rem,(md:20px,xs:10px));
  .swiper-pagination-bullet{
    transition: all .3s;
    @include res(width, .12rem,(md:10px,xs:8px));
    @include res(height, .12rem,(md:10px,xs:8px));
    &:not(:last-child){
      @include res(margin-right,.2rem,(md:10px,xs:8px));
    }
    &.swiper-pagination-bullet-active{
      background: $color-main-light;
    }
  }
}

// 列表中图片有占位图片的
.haszw-img{
  position: relative;
  overflow: hidden;
  img{
    display: block;
    width: 100%;
    height: auto;
    opacity: 0;
  }
  b,a{
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-position: center;
    background-size: cover;
    transition: all .3s;
  }
}

.pro-list{
  .pro-list-item{
    &:not(:last-child){
      border-bottom: solid $color-line;
      @include res(border-bottom-width, .01rem,(md:1px));
    }
    a{
      align-items: center;
      justify-content: space-between;
      flex-direction: row-reverse;
      @include res(display,flex,(xs:block));
      @include res(padding-top,1rem,(md:50px,sm:30px));
      @include res(padding-bottom,1.2rem,(md:60px,sm:40px));
      @include res(padding-left, 19.27%, 5 / 19.27);
      @include res(padding-right, 19.27%, 5 / 19.27);
      &:hover{
        .item-text{
          .t-more .iconfont{
            transform: translateX(5px);
          }
        }
        .item-img{
          .haszw-img b{
            transform: scale(1.05);
          }
        }
      }
      .item-img{
        .haszw-img b{
          background-size: cover;
          background-repeat: no-repeat;
        }
      }
      .item-text{
        flex-shrink: 0;
        box-sizing: border-box;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        transition: all .3s;
        color: $color-main-dark;
        @include res(text-align,left,(xs:center));
        @include res(display,flex,(xs:block));
        @include res(width,50%,(xs:100%));
        @include res(padding-left,1.9rem,(sm:1.5rem,xs:0));
        @include res(margin-bottom,0,(xs:.5rem));
        .t-top{
          text-transform: uppercase;
          @include res(margin-bottom,.15rem,(md:10px));
          @include res(margin-bottom,.8rem,(md:10px));
          h2{
            color: $color-main;
            @include res(margin-bottom,.2rem,(md:10px));
          }
          h3{
            line-height: 1.2;
            @include res(font-size, .26rem,(md:22px,sm:18px,xs:17px));
            span{
              display: block;
              @include res(font-size, .26rem,(md:22px,sm:18px,xs:17px));
            }
          }
        }
        .t-more{
          color: $color-main-light;
          @include res(font-size,.2rem,(md:16px));
          .iconfont{
            display: inline-block;
            transition: all .3s;
            @include res(margin-left,.1rem,(md:5px));
          }
        }
      }
      .item-img{
        flex-shrink: 0;
        position: relative;
        @include res(width,50%,(xs:100%));
        .detail-img{
          position: absolute;
          bottom: -8%;
          left: 0;
          z-index: 2;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          @include res(padding-left,6.66%);
          .img{
            border-radius: 50%;
            // border: solid #fff;
            overflow: hidden;
            flex-shrink: 0;
            // @include res(border-width,.01rem,(md:1px));
            &:nth-child(1){
              height: auto;
              z-index: 1;
              position: relative;
              box-shadow: 0 .1rem .2rem rgba(0,0,0,.2);
              @include res(width,2rem,(md:160px,sm:120px,xs:100px));
              @include res(height,2rem,(md:160px,sm:120px,xs:100px));
            }
            &:nth-child(2){
              height: auto;
              margin-left: -1%;
              @include res(display,none);
              @include res(width,1.6rem,(md:110px,sm:80px,xs:70px));
              @include res(height,1.6rem,(md:110px,sm:80px,xs:70px));
            }
            img{
              display: block;
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
  }
}

.news-list-item{
  position: relative;
  overflow: hidden;
  @include res(height,3rem,(md:5rem,sm:5rem,xs:3.6rem));
  @include res(border-radius,.3rem,(md:30px,sm:20px,xs:10px));
  &::after{
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    background-image: linear-gradient(to top,rgba(0,0,0,.8),rgba(0,0,0,0));
  }
  &:hover{
    b{
      transform: scale(1.05);
    }
    .t{
      .iconfont{
        background-color: $color-main;
        border-color: $color-main;
      }
    }
  }
  b{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    transition: all .3s;
  }
  .t{
    position: absolute;
    bottom: 5%;
    left: 0;
    right: 0;
    z-index: 2;
    color: #fff;
    @include res(font-size,.24rem,(md:18px,sm:16px));
    @include res(padding-left,.3rem,(md:20px));
    @include res(padding-right,.3rem,(md:20px));
    p{
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      // @include res(height,.76rem,(md:58px,sm:51px));
    }
    .iconfont{
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all .3s;
      border-style: solid;
      border-color: rgba(255,255,255,.2);
      @include res(margin-top,.25rem,(md:15px,sm:10px));
      @include res(border-width,.02rem,(md:2px,sm:1px));
      @include res(width,.5rem,(md:40px,sm:30px));
      @include res(height,.5rem,(md:40px,sm:30px));
    }
  }
}
.page-en{
  .news-list-item{
    .t{
       
      p{
        -webkit-line-clamp: 2;
        line-height: 1.4;
        // @include res(height,1.33rem,(md:100px,sm:90px));
      }
    }
  }
}


.common-map{
  position: relative;
  transform-origin: center;
  @include res(margin-top,0,(xs:.5rem));
  @include res(margin-bottom,0,(xs:.5rem));
  @include res(transform,scale(1),(xs:scale(1.2)));
  .map-img{
    display: block;
    width: 100%;
    height: auto;
  }
  ul{
    li{
      width: 1px;
      height: 1px;
      position: absolute;
      b{
        display: block;
        background-image: url(../images/map_icon.png);
        background-repeat: no-repeat;
        background-position: bottom center;
        background-size: contain;
        position: absolute;
        top: -50%;
        left: -50%;
        transform: translateX(-50%) translateY(-50%);
        transition: all .3s;
        @include res(width,1rem,(md:1.2rem,sm:1.5rem,xs:.9rem));
        @include res(height,1rem,(md:1.2rem,sm:1.5rem,xs:.9rem));
      }
      p{
        position: absolute;
        white-space: nowrap;
        color: #fff;
        font-family: 'WeiJunHe_W2';
        transform: translateX(-50%);
        @include res(font-size,.28rem,(md:16px,xs:.2rem));
        @include res(left, 50%);
        @include res(bottom, 0.1rem);
      }
      &:nth-child(1){
        top: 18%;
        left: 26.6%;
      }
      &:nth-child(2){
        top: 23.2%;
        left: 50%;
      }
      &:nth-child(3){
        top: 29%;
        left: 78%;
      }
      &:nth-child(4){
        top: 33.5%;
        left: 75.2%;
        p{
          transform: translateX(-80%);
        }
      }
      &:nth-child(5){
        top: 40.5%;
        left: 79.8%;
      }
      &:nth-child(6){
        top: 57.5%;
        left: 75.8%;
      }
    }
  }
}

.has-dian{
  @include res(line-height,1.7);
  p{
    position: relative;
    @include res(padding-left,.25rem,(md:15px));
    &:before{
      content: '';
      display: block;
      border-radius: 50%;
      background-color: $color-main-light;
      position: absolute;
      @include res(left,.05rem,(md:5px));
      @include res(top,.13rem,(md:10px));
      @include res(width,.08rem,(md:5px));
      @include res(height,.08rem,(md:5px));
    }
  }
}
.imgauto{
  width: auto !important;
}