@import "../../assets/styles/utils";

header{
  &.scroll{
    .header{
      width: 100%;
      border-bottom-color: rgba(255,255,255,.1);
      box-shadow: 0 0 .1rem rgba(0,0,0,.1);
      left: 0;
      background-color: rgba($color-main-dark,.7);
      @include res(padding-left,1rem,(md:6%,sm:5%));
      @include res(padding-right,.3rem,(md:20px,sm:10px));
      @include res(height,.7rem,$header-height-ratio);
    }
    .hidden-menu{
      @include res(padding-top,.7rem,$header-height-ratio);
    }
  }
  .header{
    position: fixed;
    top: 0;
    z-index: 1000;
    color: #fff;
    border-bottom-style: solid;
    border-bottom-color: rgba(255,255,255,1);
    align-items: center;
    justify-content: space-between;
    transition: all .5s;
    @include res(display,flex);
    @include res(border-bottom-width,.01rem,(md:1px));
    @include res(height,$header-height-base,$header-height-ratio);
    @include res(left,8.125%, 5 / 8.125);
    @include res(width,83.75%, 90 / 83.75);
    &.trans{
      transform: translateY(-100%);
    }
    .hamburger{
      flex-shrink: 0;
      @include res(display,none,(md:block));
      @include res(width, .43rem,(md:30px));
    }
    .hamburger>div, .hamburger>div:after, .hamburger>div:before, .hamburger>view, .hamburger>view:after, .hamburger>view:before{
      @include res(width, .43rem,(md:30px));
      @include res(height, .03rem,(md:2px));
    }
    .hamburger>div:before, .hamburger>view:before{
      @include res(top,-.12rem,(md:-8px));
    }
    .hamburger>div:after, .hamburger>view:after{
      @include res(bottom,-.12rem,(md:-8px));
    }
    .logo{
      flex-shrink: 0;
      @include res(top,null,(md:50%));
      @include res(left,null,(md:50%));
      @include res(transform,null,(md:translate3d(-50%,-50%,0)));
      @include res(position,null,(md:absolute));
      img{
        display: block;
        @include res(width,2.57rem,(md:200px,xs:170px));
      }
    }
    .right-cont{
      flex-shrink: 0;
      align-items: center;
      height: 100%;
      justify-content: flex-end;
      @include res(display,flex);
      .nav{
        flex-shrink: 0;
        height: 100%;
        align-items: stretch;
        justify-content: flex-end;
        @include res(display,flex,(md:none));
        .nav-item{
          height: 100%;
          position: relative;
          @include res(margin-right,.4rem,(md:30px));
          &:hover{
            .nav-title a{
              &::after{
                opacity: 1;
              }
            }
            .sub-nav{
              opacity: 1;
              visibility: visible;
              pointer-events: all;
              // transform: translateX(-50%) translateY(0);
            }
          }
          .nav-title{
            height: 100%;
            a{
              display: inline-flex;
              height: 100%;
              align-items: center;
              position: relative;
              &::after{
                content: '';
                display: block;
                border-radius: 50%;
                background-color: #fff;
                position: absolute;
                bottom: 20%;
                left: 50%;
                transform: translateX(-50%);
                opacity: 0;
                transition: all .3s;
                @include res(width,.05rem,(md:5px));
                @include res(height,.05rem,(md:5px));
              }
            }
          }
          .sub-nav{
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            white-space: nowrap;
            min-width: 100%;
            background-color: rgba($color-main-dark,.9);
            transition: all .2s;
            opacity: 0;
            visibility: hidden;
            pointer-events: none;
            @include res(min-width,2.5rem);
            @include res(top,calc(100% + .01rem),(md:calc(100% + 1px)));
            a{
              display: block;
              text-align: center;
              transition: all .4s;
              @include res(padding-top, .1rem,(md:8px));
              @include res(padding-bottom, .1rem,(md:8px));
              @include res(padding-left, .2rem,(md:10px));
              @include res(padding-right, .2rem,(md:10px));
              &:hover{
                background-color: $color-main;
              }
            }
          }
        }
      }
      .right-fun{
        height: 100%;
        align-items: center;
        @include res(display,flex);
        .search-form{
          @include res(display,null,(sm:none));
          form{
            display: flex;
            align-items: stretch;
            justify-content: flex-start;
            background-color: rgba(255,255,255,.1);
            overflow: hidden;
            @include res(border-radius,.25rem,(md:50px));
            input{
              box-sizing: border-box;
              border: none;
              background: transparent;
              color: #fff;
              @include res(font-size,.16rem,(md:16px,sm:14px));
              @include res(height,.35rem,(md:35px));
              @include res(padding-left,.2rem,(md:15px));
              @include res(width,1.55rem,(md:100px));
              &::-webkit-input-placeholder{
                color: rgba(255,255,255,.7);
              }
              &:-moz-placeholder{
                color: rgba(255,255,255,.7);
              }
              &::moz-placeholder{
                color: rgba(255,255,255,.7);
              }
              &:-ms-input-placeholder{
                color: rgba(255,255,255,.7);
              }
            }
            button{
              border: none;
              background: transparent;
              color: #fff;
              cursor: pointer;
              @include res(width,.35rem,(md:35px));
            }
          }
        }
        .language{
          position: relative;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          @include res(margin-left,.25rem,(md:15px,sm:0));
          @include res(padding-left,.15rem,(md:10px,sm:5px));
          @include res(padding-right,.15rem,(md:10px,sm:5px));
          .iconfont{
            @include res(font-size,.3rem,(md:26px,xs:22px));
          }
          &:hover{
            .language-change{
              opacity: 1;
              visibility: visible;
            }
          }
          .language-change{
            position: absolute;
            top: 80%;
            left: 50%;
            transform: translateX(-50%);
            text-align: center;
            box-shadow: 0 0 .05rem rgba(0,0,0,.1);
            border-radius: 5px;
            transition: all .3s;
            opacity: 0;
            visibility: hidden;
            a{
              display: block;
              color: #000;
              line-height: 2;
              transition: all .3s;
              background-color: #fff;
              @include res(padding-left,.1rem,(md:10px));
              @include res(padding-right,.1rem,(md:10px));
              &:hover{
                color: #fff;
                background-color: $color-main-light;
              }
              &:first-child{
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;
              }
              &:last-child{
                border-bottom-left-radius: 5px;
                border-bottom-right-radius: 5px;
              }
            }
            &::after{
              content: '';
              display: block;
              border: 5px solid;
              border-color: transparent transparent #fff transparent;
              position: absolute;
              bottom: 100%;
              left: calc(50% - 3px);
            }
          }
        }
        .person{
          height: 100%;
          align-items: center;
          justify-content: center;
          @include res(padding-left,.15rem,(md:10px,sm:5px));
          @include res(display,flex);
          .open-login{
            @include res(font-size,.18rem,(md:16px,xs:14px));
          }
          .head-img{
            border-radius: 50%;
            overflow: hidden;
            background: rgba(255,255,255,.1);
            align-items: center;
            justify-content: center;
            @include res(width,.36rem,(md:36px,xs:32px));
            @include res(height,.36rem,(md:36px,xs:32px));
            @include res(display,none);
            .iconfont{
              @include res(font-size, .18rem,(md:16px,xs:14px));
            }
            img{
              display: block;
              width: 100%;
              height: 100%;
            }
          }
          &.logged{
            .open-login{
              display: none;
            }
            .head-img{
              display: flex;
            }
          }
        }
      }
    }
  }

  .hidden-menu{
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 998;
    transition: padding-top .3s;
    pointer-events: none;
    display: none;
    @include res(padding-top,$header-height-base,$header-height-ratio);
    &.active{
      pointer-events: all;
      .bg-img{
        opacity: 1;
        visibility: visible;
        transition-delay: 0s;
      }
      .menu-cont{
        .left{
          transition-delay: .2s;
          transform: translateX(0);
        }
        .right{
          .right-item{
            opacity: 1;
            visibility: visible;
            transform: translateY(0);
            &:nth-child(1){
              transition-delay: .3s;
            }
            &:nth-child(2){
              transition-delay: .4s;
            }
            &:nth-child(3){
              transition-delay: .5s;
            }
          }
        }
      }
    }
    .bg-img{
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      transition: all .4s;
      opacity: 0;
      visibility: hidden;
      @include res(transition-delay,.5s,(xs:0s));
      &::after{
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,.7);
        position: absolute;
        top: 0;
        left: 0;
      }
    }
    .menu-cont{
      height: 100%;
      align-items: stretch;
      justify-content: space-between;
      @include res(display,flex,(xs:block));
      .left{
        flex-shrink: 0;
        height: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        overflow-y: auto;
        transition: all .4s;
        transform: translateX(-101%);
        @include res(background-color,#fff,(xs:rgba(255,255,255,.8)));
        @include res(transition-delay,.4s,(xs:0s));
        @include res(width,25%,(md:40%,xs:100%));
        .left-top{
          border-bottom-style: solid ;
          width: 100%;
          color: #000;
          @include res(border-bottom-color,$color-line,(xs:rgba(255,255,255,.3)));
          @include res(border-bottom-width,.01rem,(md:1px));
          @include res(padding-left,.55rem,(md:30px,sm:20px,xs:15px));
          @include res(padding-right,.35rem,(md:20px,sm:15px));
          @include res(padding-top,.3rem,(md:20px,sm:15px));
          .person{
            display: flex;
            align-items: center;
            justify-content: space-between;
            .t{
              @include res(font-size, .24rem,(md:20px,xs:18px));
            }
            .img{
              border-radius: 50%;
              position: relative;
              background-color: $color-main;
              @include res(width,.42rem,(md:40px,sm:30px));
              @include res(height,.42rem,(md:40px,sm:30px));
              &::after{
                content: '\e688';
                font-family: 'iconfont';
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                background-color: #fbf8ed;
                position: absolute;
                bottom: 0;
                right: 0;
                color: #fa9d00;
                transform: translate3d(20%,20%,0);
                @include res(font-size,.12rem,(md:12px));
                @include res(width,.18rem,(md:18px));
                @include res(height,.18rem,(md:18px));
              }
              img{
                display: block;
                width: 100%;
                height: 100%;
                border-radius: 50%;
              }
            }
          }
          .language{
            position: relative;
            display: inline-block;
            @include res(padding-bottom,.05rem,(md:5px));
            @include res(font-size,.14rem,(md:14px));
            &:hover{
              .hidden-cont{
                opacity: 1;
                visibility: visible;
              }
            }
            .title{
              cursor: pointer;
              .iconfont{
                display: inline-block;
                transform-origin: center;
                transform: rotate(90deg);
                color: $color-desc;
                @include res(font-size,.14rem,(md:14px));
                @include res(margin-left,.1rem,(md:5px));
              }
            }
            .hidden-cont{
              position: absolute;
              top: 105%;
              left: 0;
              background-color: $color-main;
              color: #fff;
              width: 100%;
              text-align: center;
              transition: all .3s;
              opacity: 0;
              visibility: hidden;
              &::after{
                content: '';
                display: block;
                border: 5px solid;
                border-color: transparent transparent $color-main transparent;
                position: absolute;
                bottom: 100%;
                left: calc(50% - 3px);
              }
              a{
                display: block;
                transition: all .3s;
                @include res(padding-top,.05rem,(md:5px));
                @include res(padding-bottom,.05rem,(md:5px));
                &:hover{
                  background-color: $color-main-light;
                }
              }
            }
          }
        }
        .left-nav{
          width: 100%;
          overflow-y: auto;
          @include res(max-height,60vh);
          @include res(padding-left,.55rem,(md:30px,sm:20px,xs:15px));
          @include res(padding-right,.35rem,(md:20px,sm:15px));
          ul{
            li{
              &.active{
                .nav-title{
                  a{
                    color: $color-main;
                  }
                }
              }
              &.show-sub{
                .nav-title{
                  .iconfont{
                    transform: rotate(90deg);
                  }
                }
              }
              .nav-title{
                display: flex;
                align-items: stretch;
                justify-content: space-between;
                a{
                  display: block;
                  transition: all .3s;
                  flex-grow: 1;
                  color: #000;
                  @include res(font-size,.2rem,(md:18px));
                  @include res(padding-top,.15rem,(md:10px));
                  @include res(padding-bottom,.15rem,(md:10px));
                  &.active,&:hover{
                    color: $color-main;
                  }
                }
                .iconfont{
                  align-items: center;
                  justify-content: center;
                  width: 50px;
                  transition: all .3s;
                  transform-origin: center;
                  @include res(display,none,(md:flex));
                }
              }
              .sub-nav{
                display: none;
                @include res(font-size,16px,(xs:14px));
                a{
                  display: inline-block;
                  width: 48%;
                  margin-bottom: 5px;
                }
              }
            }
          }
          
        }
        .left-search{
          width: 100%;
          @include res(margin-top,.9rem,(md:30px));
          @include res(margin-bottom,.9rem,(md:30px));
          @include res(padding-left,.3rem,(md:20px,sm:15px));
          @include res(padding-right,.3rem,(md:20px,sm:15px));
          h4{
            text-align: center;
            color: #000;
            @include res(margin-bottom,.2rem,(md:10px));
            @include res(font-size,.28rem,(md:24px,sm:22px,xs:20px));
          }
          form{
            display: flex;
            align-items: stretch;
            justify-content: space-between;
            border-radius: 50px;
            overflow: hidden;
            border: solid #4c4c4c;
            @include res(border-width,.01rem,(md:1px));
            input{
              min-width: 100px;
              flex-grow: 1;
              border: none;
              background: transparent;
              @include res(padding-left,.35rem,(md:20px,sm:15px));
              @include res(height,.58rem,(md:40px));
            }
            button{
              cursor: pointer;
              border: none;
              background: transparent;
              @include res(width,.7rem,(md:60px));
              .iconfont{
                @include res(font-size,.28rem,(md:24px));
              }
            }
          }
        }
      }
      .right{
        flex-shrink: 0;
        align-items: stretch;
        justify-content: space-between;
        color: #fff;
        @include res(display,flex,(md:block,xs:none));
        @include res(width,75%,(md:60%));
        @include res(overflow-y,null,(md:auto));
        .right-item{
          flex-shrink: 0;
          box-sizing: border-box;
          transition: all .4s;
          opacity: 0;
          visibility: hidden;
          transform: translateY(30%);
          @include res(padding-top,1.1rem,(md:25px));
          @include res(padding-bottom,.5rem,(md:25px));
          @include res(padding-left,.85rem,(md:120px,sm:80px));
          @include res(padding-right,.25rem,(md:15px));
          @include res(width,33.33%,(md:100%));
          @include res(height,100%,(md:auto));
          &:nth-child(1){
            transition-delay: .3s;
          }
          &:nth-child(2){
            transition-delay: .2s;
          }
          &:nth-child(3){
            transition-delay: .1s;
          }
          &:not(:first-child){
            border-left: solid rgba(255,255,255,.1);
            @include res(border-left-width, .01rem,(md:1px));
          }
          .item-title{
            position: relative;
            @include res(margin-bottom,.55rem,(md:30px,sm:15px));
            h2{
              @include res(font-size,.24rem,(md:22px));
              @include res(margin-bottom,.1rem,(md:10px));
            }
            h3{
              @include res(font-size,.16rem,(md:16px));
            }
            .num{
              color: rgba(255,255,255,.6);
              position: absolute;
              top: 5%;
              left: -8%;
              @include res(font-size,.2rem,(md:18px));
            }
            &::after{
              content: '';
              display: block;
              position: absolute;
              top: 0;
              left: -13%;
              background-color: rgba(255,255,255,.5);
              @include res(height,.9rem,(md:70px,sm:50px));
              @include res(width,.02rem,(md:2px));
            }
          }
          .item-link{
            a{
              display: flex;
              align-items: center;
              @include res(margin-bottom,.15rem,(md:10px));
              @include res(font-size, .16rem,(md:14px));
              &:hover{
                .iconfont{
                  @include res(margin-right,.15rem,(md:15px));
                }
              }
              .iconfont{
                transition: all .3s;
                @include res(font-size, .16rem,(md:14px));
                @include res(margin-right,.1rem,(md:10px));
              }
            }
          }
        }
      }
    }
  }

}


.page-en{
  header{
    .header{
      .right-cont{
        .nav{
          @include res(font-size,.16rem,(md:16px));
          .nav-item{
            @include res(margin-right,.3rem,(md:30px));
            .nav-title{
              text-transform: uppercase;
            }
          }
        }
      }
    }
    .hidden-menu{
       
      .menu-cont{
        .left{
          .left-nav{
            ul li{
              .nav-title{
                a{
                  @include res(font-size,.18rem,(md:16px));
                }
              }
            }
          }
        }
        .right{
          .right-item{
            .item-title{
              h2{
                @include res(font-size,.22rem,(md:20px));
              }
              h3{
                opacity: .6;
              }
            }
            .item-link{
              a{
                opacity: .6;
              }
            }
          }
        }
      }
    }
  }
}

// .page-en{
//   header .header .right-cont .nav .nav-item .sub-nav{
//     @include res(min-width,2.5rem);
//   }
// }