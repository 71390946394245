@font-face {
  font-family: "iconfont"; /* Project id 4138968 */
  src: url('iconfont.woff?t=1698980682142') format('woff'),
       url('iconfont.ttf?t=1698980682142') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-dizhiguanli:before {
  content: "\e63f";
}

.icon-dianhua:before {
  content: "\e88b";
}

.icon-youxiang:before {
  content: "\e908";
}

.icon-jiantou-qiehuanyou_1:before {
  content: "\e7a9";
}

.icon-jiantou-qiehuanzuo:before {
  content: "\e7aa";
}

.icon-bofang:before {
  content: "\e87c";
}

.icon-lianxiwomen:before {
  content: "\e612";
}

.icon-inbox:before {
  content: "\e896";
}

.icon-youtube:before {
  content: "\e73b";
}

.icon-gengduo:before {
  content: "\e641";
}

.icon-sanjiaoxing:before {
  content: "\e624";
}

.icon-tansuo:before {
  content: "\e622";
}

.icon-fuwu:before {
  content: "\e607";
}

.icon-fuwuneirong:before {
  content: "\e627";
}

.icon-dingdan:before {
  content: "\e897";
}

.icon-jingyanzhi:before {
  content: "\e60c";
}

.icon-yanjing_kai:before {
  content: "\e61b";
}

.icon-back:before {
  content: "\e636";
}

.icon-gouwudai:before {
  content: "\e628";
}

.icon-richu:before {
  content: "\e61c";
}

.icon-VIP:before {
  content: "\e688";
}

.icon-duihao:before {
  content: "\eaf1";
}

.icon-you:before {
  content: "\ee4a";
}

.icon-zuo:before {
  content: "\e601";
}

.icon-31sousuo:before {
  content: "\e600";
}

.icon-weixin:before {
  content: "\e65d";
}

.icon-diqiu:before {
  content: "\e689";
}

.icon-denglu:before {
  content: "\e611";
}

.icon-linkedin-in:before {
  content: "\ec0e";
}

.icon-tuite:before {
  content: "\ee49";
}

