@import "../../assets/styles/utils";

footer{
  background-size: auto 100%;
  @include res(background,url(./images/footer_bg.jpg) no-repeat right center $color-main-dark,(xs:$color-main-dark));
  .foot1{
    align-items: center;
    justify-content: space-between;
    border-bottom: solid rgba(255,255,255,.1);
    @include res(padding-top,.78rem,(md:40px,sm:30px,xs:20px));
    @include res(padding-bottom,.78rem,(md:40px,sm:30px,xs:20px));
    @include res(border-bottom-width,.01rem,(md:1px));
    @include res(display,flex,(xs:block));
    .left{
      flex-shrink: 0;
      img{
        display: block;
        height: auto;
        margin: 0 auto;
        @include res(width,2.57rem,(md:257px,xs:60%));
      }
    }
    .right{
      flex-shrink: 0;
      text-align: center;
      @include res(font-size,.18rem,(md:16px,xs:14px));
      @include res(margin-top,0,(xs:15px));
      a{
        display: inline-block;
        color: #fff;
        position: relative;
        &::after{
          content: '';
          display: block;
          width: 100%;
          background-color: rgba(255,255,255,.8);
          position: absolute;
          bottom: 0;
          left: 0;
          transform-origin: center;
          transition: all .3s;
          transform: scaleX(0);
          @include res(height,.02rem,(md:2px));
        }
        &:not(:last-child){
          @include res(margin-right,.3rem,(md:20px,xs:10px));
        }
        &:hover{
          &::after{
            transform: scaleX(1);
          }
        }
      }
    }
  }
  .foot2{
    color: #fff;
    @include res(padding-bottom,.9rem,(md:50px,sm:30px,xs:20px));
    .foot2-top{
      align-items: flex-end;
      justify-content: space-between;
      @include res(display,flex,(xs:block));
      @include res(padding-top,.3rem,(md:20px,xs:10px));
      .left{
        flex-shrink: 0;
        h4{
          font-weight: lighter;
          text-transform: uppercase;
          @include res(margin-bottom,.3rem,(md:20px,xs:10px));
          @include res(font-size,.48rem,(md:30px,sm:26px,xs:22px));
        }
        form{
          display: flex;
          align-items: stretch;
          justify-content: space-between;
          input{
            flex-shrink: 0;
            border-radius: 5px;
            border: none;
            background-color: rgba(255,255,255,.1);
            box-sizing: border-box;
            color: #fff;
            @include res(padding-left,.3rem,(md:20px,xs:10px));
            @include res(padding-right,.3rem,(md:20px,xs:10px));
            @include res(height,.7rem,(md:60px,sm:50px,xs:40px));
            @include res(width,6rem,(md:400px,sm:300px,xs:70%));
            @include res(margin-right,.2rem,(md:10px));
            &::-webkit-input-placeholder{
              color: rgba(255,255,255,.5);
            }
            &:-moz-placeholder{
              color: rgba(255,255,255,.5);
            }
            &::moz-placeholder{
              color: rgba(255,255,255,.5);
            }
            &:-ms-input-placeholder{
              color: rgba(255,255,255,.5);
            }
          }
          button{
            flex-shrink: 0;
            border: none;
            cursor: pointer;
            background-color: $color-main-light;
            color: #fff;
            border-radius: 5px;
            transition: all .3s;
            @include res(width,1.6rem,(md:100px,xs:calc(30% - 10px)));
            &:hover{
              background-color: $color-main;
            }
          }
        }
      } 
      .right{
        flex-shrink: 0;
        @include res(margin-top,0,(xs:20px));
        .icon-list{
          display: flex;
          align-items: center;
          @include res(justify-content,flex-end,(xs:center));
          .item{
            position: relative;
            &:not(:first-child){
              @include res(margin-left,.35rem,(md:20px));
            }
            .iconfont{
              transition: all .3s;
              @include res(font-size,.34rem,(md:26px));
            }
            .ewm{
              position: absolute;
              top: 100%;
              left: 50%;
              transform: translateX(-50%);
              transition: all .3s;
              z-index: 2;
              opacity: 0;
              visibility: hidden;
              img{
                display: block;
                height: auto;
                @include res(width,1.45rem,(md:100px));
              }
              &::after{
                content: '';
                display: block;
                border: 5px solid;
                border-color: transparent transparent #fff transparent;
                position: absolute;
                bottom: 100%;
                left: calc(50% - 3px);
              }
            }
            &:hover{
              .ewm{
                opacity: 1;
                visibility: visible;
              }
            }
            &:nth-child(1):hover{
              .iconfont{
                color: #28C445;
              }
            }
            &:nth-child(2):hover{
              .iconfont{
                color: #156da0;
              }
            }
            &:nth-child(3):hover{
              .iconfont{
                color: #1296db;
              }
            }
          }
        }
        .phone{
          font-family: 'aven';
          line-height: 1.1;
          @include res(text-align,null,(xs:center));
          @include res(margin-top,.3rem,(md:20px,xs:10px));
          @include res(font-size,.48rem,(md:36px,xs:24px));
        }
      }
    }
    .foot-nav{
      @include res(display,block);
      @include res(margin-top,.45rem,(md:30px,sm:20px));
      ul{
        align-items: flex-start;
        justify-content: space-between;
        @include res(display,flex,(xs:block));
        li{
          flex-shrink: 0;
          flex-grow: 1;
          @include res(border-bottom,null,(xs:1px solid rgba(255,255,255,.4)));
          @include res(max-width,16.66%,(xs:100%));
          &.active{
            .nav-title{
              .iconfont{
                transform: rotate(90deg);
              }
            }
          }
          .nav-title{
            position: relative;
            display: flex;
            align-items: stretch;
            justify-content: space-between;
            text-transform: uppercase;
            @include res(margin-bottom,.05rem,(md:5px,xs:0));
            @include res(font-size,.24rem,(md:20px,xs:18px));
            &::after{
              content: '';
              background-color: rgba(255,255,255,.8);
              position: absolute;
              bottom: 50%;
              right: 103%;
              transform-origin: right;
              transition: all .3s;
              transform: scaleX(0) translateY(-50%);
              @include res(display,block,(xs:none));
              @include res(width,.2rem);
              @include res(height,.02rem,(md:2px));
            }
            &:hover{
              &::after{
                transform: scaleX(1);
              }
            }
            a{
              flex-grow: 1;
              display: block;
              @include res(padding-top,0,(xs:8px));
              @include res(padding-bottom,0,(xs:8px));
            }
            .iconfont{
              width: 50px;
              flex-shrink: 0;
              align-items: center;
              justify-content: center;
              transition: all .3s;
              transform-origin: center;
              @include res(display,none,(xs:flex));
            }
          }
          .sub-nav{
            @include res(font-size,.18rem,(md:16px));
            @include res(display,block,(xs:none));
            @include res(padding-bottom,0,(xs:10px));
            a{
              display: inline-block;
              @include res(width,100%,(xs:48%));
              @include res(margin-top,.25rem,(md:15px,xs:5px));
              span{
                display: inline-block;
                position: relative;
                &::after{
                  content: '';
                  display: block;
                  width: 100%;
                  background-color: rgba(255,255,255,.8);
                  position: absolute;
                  bottom: 0;
                  left: 0;
                  transform-origin: center;
                  transition: all .3s;
                  transform: scaleX(0);
                  @include res(height,.02rem,(md:2px));
                }
                &:hover{
                  &::after{
                    transform: scaleX(1);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .foot3{
    color: rgba(255,255,255,.5);
    text-transform: uppercase;
    .location-tab{
      align-items: center;
      justify-content: center;
      @include res(display,flex);
      @include res(font-size,.2rem,(md:18px));
      div{
        position: relative;
        cursor: pointer;
        transition: all .3s;
        @include res(padding-bottom,.15rem,(md:10px));
        &:not(:last-child){
          @include res(margin-right,1.1rem,(md:50px));
        }
        &::after{
          content: '';
          display: block;
          width: 100%;
          background-color: rgba(255,255,255,1);
          position: absolute;
          bottom: 0;
          left: 0;
          transform-origin: center;
          transition: all .3s;
          transform: scaleX(0);
          @include res(height,.02rem,(md:2px));
        }
        &.active{
          color: #fff;
          &::after{
            transform: scaleX(1);
          }
        }
      }
    }
    .location-t{
      text-align: center;
      position: relative;
      @include res(margin-top,.15rem,(md:10px));
      @include res(margin-bottom,.35rem,(md:20px));
      .swiper{
        .swiper-slide{
          a{display: inline-block;}
        }
      }
    }
  }
  .foot4{
    border-top: solid rgba(255,255,255,.1);
    text-align: center;
    color: rgba(255,255,255,.2);
    text-transform: uppercase;
    @include res(padding-top,.55rem,(md:30px,sm:20px,xs:10px));
    @include res(padding-bottom,.55rem,(md:30px,sm:20px,xs:10px));
    @include res(font-size,.18rem,(md:16px,xs:12px));
    @include res(border-top-width,.01rem,(md:1px));
    a{
      transition: all .3s;
      display: inline-block;
      &:hover{
        color: rgba(255,255,255,.5);
      }
    }
  }
}

.page-en{
  footer{
    .foot2{
      .foot2-top{
        .left{
          h4{
             
          }
        }
      }
      .foot-nav{
        ul{
          li{
            flex-grow: 0;
            .nav-title{
              @include res(font-size,.2rem,(md:18px,xs:16px));
            }
            .sub-nav{
              @include res(font-size,.16rem,(md:16px,xs:14px));
            }
          }
        } 
      }
    }
    .foot3{
      .location-tab{
        @include res(font-size,.2rem,(md:18px,xs:14px));
        div{
          &:not(:last-child){
            @include res(margin-right,1.1rem,(md:50px,xs:20px));
          }
        }
      }
      .location-t{
        @include res(font-size,null,(xs:12px));
      }
    }
  }
}