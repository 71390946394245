@import "../../assets/styles/utils";

.pages,#page ul{
  text-align: center;
  @include res(margin-top,.8rem,(md:30px));
  a,li{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border-radius: 3px;
    border: 1px solid #a8acbc;
    transition: all .3s;
    @include res(width,.44rem,(md:34px,xs:30px));
    @include res(height,.44rem,(md:34px,xs:30px));
    &:not(:last-child){
      @include res(margin-right,.1rem,(md:5px));
    }
    &.a1{
      color: #a8acbc;
    }
    &:hover{
      border: 1px solid $color-main;
      color: $color-main;
    }
    &.active{
      border: 1px solid $color-main;
      color: $color-main;
    }
    .iconfont{
      @include res(font-size,.2rem,(md:16px));
    }
  }
  // display: flex;
  // align-items: center;
  // justify-content: center;
  // flex-wrap: wrap;
  // @include res(margin-top,50px, 20 / 50);
  // a,li{
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  //   border-radius: 50%;
  //   background-color: #f2f2f3;
  //   transition: $anime-duration $anime-bezier;
  //   cursor: pointer;
  //   transition: all .4s;
  //   @include res(margin-bottom,5px);
  //   @include res(margin-left,10px, 5 / 10);
  //   @include res(margin-right,10px, 5 / 10);
  //   @include res(font-size, 18px, 16 / 18);
  //   @include res(width, 50px, 40 / 50);
  //   @include res(height, 50px, 40 / 50);
  //   &:hover{
  //     background-color: #ebebeb;
  //     color: $color-main;
  //   }
  //   &.active,&.xl-active{
  //     background-color: $color-main;
  //     color: #fff;
  //   }
  //   &.xl-prevPage,&.xl-nextPage{
  //     display: none;
  //   }
  // }
  // .page_jump_text{
  //   @include res(margin-left,10px);
  //   @include res(margin-right,10px);
  // }
  // .page_jump_input{
  //   @include res(width,60px, 60 / 60);
  //   @include res(height,30px,30 / 30);
  // }
  // .page_jump_btn{
  //   background: none;
  //   border: none;
  //   cursor: pointer;
  //   transition: all;
  //   &:hover{
  //     color: $color-main;
  //   }
  // }
}